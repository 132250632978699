import { GetStaticProps, InferGetStaticPropsType } from "next";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { getAllExhibitData } from "~/lib/exhibit";
import { Level } from "~/types/hotspots";

const Scene = dynamic(() => import("~/components/home/Scene"), {
  ssr: false,
});

export const getStaticProps: GetStaticProps<{
  partialLevels: Partial<Level>[];
}> = async () => {
  const levels = await getAllExhibitData();
  const partialLevels = levels.map((level) => ({
    slug: level.slug,
    title: level.title,
  }));

  return {
    props: {
      partialLevels,
    },
  };
};

export default function Home({
  partialLevels,
}: InferGetStaticPropsType<typeof getStaticProps>) {
  const router = useRouter();
  return (
    <Scene
      partialLevels={partialLevels}
      onClick={(slug) => router.push(`/player/${slug}`)}
    />
  );
}
